import { ref, reactive, computed, onMounted } from 'vue';
import {
    UnQulifiedData,
    QulifiedData,
    SearchParams,
} from '@/views/SalesReporting/PrepaymentQualified/OEM/types';
import * as API from '@/API/salesReporting';
import downloadFile from '@/utils/claim/downloadFile';
import { ColmunsProps } from '@/API/types';
import { useStore } from 'vuex';
import { getBuSubregion } from '@/API/salesReporting/claimCheckingReport';

const useOEMPrepaymentQualified = () => {
    const store = useStore();
    let defaultBu: string | undefined;
    const searchParams = reactive<SearchParams>({
        bu: undefined,
        subRegion: undefined,
        dealerGroup: '',
        dealerCode: '',
        dealerName: '',
    });
    const activeTab = ref('unQulified');
    const qulifiedList = ref<QulifiedData[]>([]);
    const unQulifiedList = ref<UnQulifiedData[]>([]);

    const qulifiedCol = [
        {
            title: 'Sub Region',
            dataIndex: 'region',
            width: 120,
        },
        {
            title: 'Dealer Group',
            dataIndex: 'dealerGroup',
            width: 150,
        },
        {
            title: 'Dealer Code',
            dataIndex: 'dealerCode',
            width: 150,
        },
        {
            title: 'Dealer Name CN',
            dataIndex: 'dealerName',
            width: 300,
        },
    ];
    const unQulifiedCol = ref<ColmunsProps[]>([
        // {
        //     title: 'Sub Region',
        //     dataIndex: 'subRegion',
        //     width: 120,
        // },
        // {
        //     title: 'Dealer Group',
        //     dataIndex: 'dealerGroup',
        //     width: 150,
        // },
        // {
        //     title: 'Dealer Code',
        //     dataIndex: 'dealerCode',
        //     width: 150,
        // },
        // {
        //     title: 'Dealer Name CN',
        //     dataIndex: 'dealerName',
        //     width: 300,
        // },
    ]);
    // const customCells = (record: any, rowIndex: any) => {
    //     return {
    //         class:
    //             'ant-table-row-cell-break-word ant-table-cell-fix-left ant-table-cell-fix-left-last right-border',
    //     };
    // };
    // const customHeaderCells = (column: any) => {
    //     return {
    //         class: 'right-border',
    //     };
    // };
    const tableWidth = ref(0);
    const doSearch = (flag: string) => {
        const params = {
            bu: searchParams.bu,
            subRegion: searchParams.subRegion,
            dealerGroup: searchParams.dealerGroup,
            dealerCode:
                searchParams.dealerCode === ''
                    ? undefined
                    : [searchParams.dealerCode],
            dealerName: searchParams.dealerName,
            type: 'OEM',
        };
        if (flag === 'unQulified') {
            // 不合格
            unQulifiedCol.value = [
                {
                    title: 'Sub Region',
                    dataIndex: 'subRegion',
                    width: 120,
                    fixed: 'left',
                },
                {
                    title: 'Dealer Group',
                    dataIndex: 'dealerGroup',
                    width: 150,
                    fixed: 'left',
                },
                {
                    title: 'Dealer Code',
                    dataIndex: 'dealerCode',
                    width: 150,
                    fixed: 'left',
                },
                {
                    title: 'Dealer Name CN',
                    dataIndex: 'dealerName',
                    width: 300,
                    fixed: 'left',
                    // customCell: customCells,
                    // customHeaderCell: customHeaderCells,
                },
            ];
            unQulifiedList.value = [];
            API.unQualifiedDealerReportList(params).then((data) => {
                if (data.payoutRoundList.length > 0) {
                    data.programDealerDetailListRespVOS.forEach((item: any) => {
                        const unQulifiedData = { ...item };
                        item.uploadStatus.forEach((element: any) => {
                            const payoutRound = Object.keys(element)[0];
                            unQulifiedData[payoutRound] = {
                                paymentStatementUpload:
                                    element[payoutRound][0]
                                        .paymentStatementUpload === false
                                        ? '缺特殊折让明细'
                                        : '',
                                paymentIntroductionFileUpload:
                                    element[payoutRound][0]
                                        .paymentIntroductionFileUpload === false
                                        ? '未收到红票通知单'
                                        : '',
                            };
                        });
                        unQulifiedList.value.push(unQulifiedData);
                    });
                }
                for (let i = 0; i < data.payoutRoundList.length; i++) {
                    const payoutRound = data.payoutRoundList[i];
                    unQulifiedCol.value.push({
                        title: payoutRound,
                        dataIndex: payoutRound,
                        children: [
                            {
                                title: 'Payment Statement',
                                dataIndex: `${payoutRound}.paymentStatementUpload`,
                                key: `${payoutRound}.paymentStatementUpload`,
                                width: 150,
                                align: 'center',
                            },
                            {
                                title: 'Red Invoice Notice',
                                dataIndex: `${payoutRound}.paymentIntroductionFileUpload`,
                                key: `${payoutRound}.paymentIntroductionFileUpload`,
                                width: 150,
                                align: 'center',
                            },
                        ],
                    });
                }
                console.log('unQulifiedCol.value', unQulifiedCol.value);
                tableWidth.value = unQulifiedCol.value.reduce(
                    (total: number, col: any) => {
                        if (!col.children) {
                            return total + col.width;
                        } else {
                            return total + 2 * 150;
                        }
                    },
                    0
                );
            });
        } else {
            // 合格
            qulifiedList.value = [];
            API.qualifiedDealerReportList(params).then((data) => {
                qulifiedList.value = data;
            });
        }
    };

    const searchClick = () => {
        doSearch(activeTab.value);
    };

    const downloadClick = () => {
        const params = {
            url: '/ptapi/dealer/report/downloadUnQualifiedDealerReport',
            method: 'post',
            data: {},
            params: {
                bu: searchParams.bu,
                dealerName: searchParams.dealerName,
                subRegion: searchParams.subRegion,
                dealerGroup: searchParams.dealerGroup,
                dealerCode:
                    searchParams.dealerCode === ''
                        ? undefined
                        : [searchParams.dealerCode],
                type: 'OEM',
            },
        };
        if (activeTab.value === 'unQulified') {
            params.url = '/ptapi/dealer/report/downloadUnQualifiedDealerReport';
        } else {
            params.url = '/ptapi/dealer/report/downloadQualifiedDealerReport';
        }
        downloadFile(params, '');
    };

    const tabChange = () => {
        doSearch(activeTab.value);
    };

    const init = () => {
        doSearch('unQulified');
        doSearch('qulified');
    };

    const buList = computed(() =>
        store.state.user.buList.filter((bu: any) => {
            return bu.parentId === null;
        })
    );
    const getSelectBuId = () => {
        const index = buList.value.findIndex(
            (item: any) => item.nameEn === searchParams.bu
        );
        return buList.value[index]?.id;
    };

    const dogetDefault = () => {
        const mbIndex = buList.value.findIndex((i: any) => {
            return i.nameEn === 'MBPC';
        });
        const initIndex = mbIndex === -1 ? 0 : mbIndex;
        searchParams.bu = buList.value[initIndex].nameEn;
        defaultBu = buList.value[initIndex].nameEn;
    };
    dogetDefault();

    const subRegionList = ref<any>([]);
    const handleBuChange = () => {
        const buid = getSelectBuId();
        getBuSubregion({ buId: `${buid}` }).then((res) => {
            // 北东西南 顺序排序
            const sortArr: any[] = [[], [], [], [], []];
            const maps = {
                n: 0,
                e: 1,
                s: 2,
                w: 3,
            };
            res.forEach((region: any) => {
                const firstLetter = (region
                    .regionEn[0] as string).toLocaleLowerCase();
                if (firstLetter in maps) {
                    sortArr[maps[firstLetter]].push(region);
                } else {
                    sortArr[4].push(region);
                }
            });
            subRegionList.value = sortArr.flat();
            searchParams.subRegion = undefined;
        });
    };

    const resetClick = () => {
        Object.assign(searchParams, {
            bu: defaultBu,
            subRegion: undefined,
            dealerGroup: '',
            dealerCode: '',
            dealerName: '',
        });
        // doSearch(activeTab.value);
        handleBuChange();
    };

    onMounted(() => {
        handleBuChange();
        init();
    });

    return {
        getSelectBuId,
        init,
        searchParams,
        activeTab,
        unQulifiedList,
        qulifiedList,
        qulifiedCol,
        unQulifiedCol,
        searchClick,
        resetClick,
        downloadClick,
        tabChange,
        handleBuChange,
        buList,
        subRegionList,
        tableWidth,
    };
};
export default useOEMPrepaymentQualified;
